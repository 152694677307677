import React from 'react';
import _ from 'lodash';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import { format } from 'date-fns';

import Layout from './layout';
import Hero from './hero';
import Grid from './grid';

import GetProperties from '../utils/get-properties';

import arrowLeft from '../images/arrow-left.svg';

export default ({ data, location = false }) => {
  const propertiesSameLocationAndCategory = GetProperties({
    hideSlug: data.slug,
    category: data.category,
    location: data.location,
    limit: 20,
  });

  const propertiesSameLocation = GetProperties({
    hideSlug: data.slug,
    location: data.location,
    limit: 20,
  });

  const propertiesSameCategory = GetProperties({
    hideSlug: data.slug,
    category: data.category,
    limit: 20,
  });

  const gridPropertiesUnsorted = _.uniqBy(
    [
      ...propertiesSameLocationAndCategory,
      ...propertiesSameCategory,
      ...propertiesSameLocation,
    ],
    'strapiId',
  );

  const gridProperties = [
    ...gridPropertiesUnsorted.filter(p => p.status == 'available'),
    ...gridPropertiesUnsorted.filter(p => p.status != 'available'),
  ].slice(0, 20);

  const backgroundImage = data.featured_image;

  let applyHref = `https://apply.tpsportal.co.nz/tps7542`;

  return (
    <Layout
      singleProperty={true}
      title={`DPM - ${data.street_address}`}
      description={
        data.description ? data.description.substring(0, 160) : false
      }
    >
      <Hero
        backgroundImage={backgroundImage}
        additionalClasses="is-fullheight"
        gallery={data.gallery}
      />
      <StyledProperty className="section">
        <div className="container">
          <div
            className="columns"
            style={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {location && location.state && location.state.fromSite && (
              <div className="column is-full" style={{ paddingTop: 0 }}>
                <a
                  className="backLink is-uppercase is-size-7"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </a>
              </div>
            )}
            <div className="column is-full">
              <h1 className="is-size-6" style={{ fontWeight: 500, marginBottom: 0 }}>
                {data.street_address}
              </h1>
              <p className="meta is-uppercase is-size-6 show-on-mobile-only" style={{ color: '#999' }}>
                {data.bedrooms ? <span>bed {data.bedrooms} </span> : <></>}
                {data.bathrooms ? <span>bath {data.bathrooms} </span> : <></>}
                {data.parks ? <span>park {data.parks}</span> : <></>}
              </p>
              


              <p className="meta is-size-6 is-uppercase hide-on-mobile" style={{ color: '#999' }}>
                {data.bedrooms ? <span>bed {data.bedrooms} </span> : <></>}
                {data.bathrooms ? <span>bath {data.bathrooms} </span> : <></>}
                {data.parks ? <span>park {data.parks}</span> : <></>}
              </p>
              
              <p
                className=" is-size-6 is-uppercase"
                style={{ fontWeight: 500, color: '#999' }}
              >
                <span>{data.status ? data.status : 'available'} </span>
                {data.available_from ? (
                  format(new Date(data.available_from), 'd MMM yyyy')
                ) : (
                  <></>
                )}
              </p>
              <p
                className="is-size-6 is-uppercase"
                style={{ fontWeight: 500, color: '#999' }}
              >
                {data.weekly_rent ? '$' + data.weekly_rent + 'pw' : '$tbc'}
              </p>
            </div>
            <div className="column  is-full">
              <div className="mainBlock">
                <ReactMarkdown source={data.description} />
              </div>
              <div
                className="columns buttonOutside"
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                {data.member ? (
                  <div className="column is-narrow agent">
                    <div className="media" style={{ borderRadius: 0 }}>
                      <div className="media-content">
                          <p className="is-uppercase is-size-7" style={{ fontWeight: '500' }}>
                            {data.member.Name}
                          </p>
                        <p className=" is-uppercase is-size-7" style={{ color: '#999', fontWeight: 500 }}>
                            {data.member.Position}
                        </p>
                        <p className=" is-uppercase is-size-7" >
                          <a href={'mailto:' + data.member.Email} style={{ color: '#999', fontWeight: 500 }}>
                              {data.member.Email}
                            </a>
                          </p>
                          <p className="is-uppercase is-size-7">
                          <a href={'tel:' + data.member.phone_number_linkable} style={{ color: '#999', fontWeight: 500 }}>
                              {data.member.phone_number}
                            </a>
                          </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {data.status == 'available' ? (
                  <div className="buttonContainer column is-narrow">
                    <a
                      target="_blank"
                      className="button first"
                      href={`https://dpmgroup.viewingtracker.com/property/${
                        data.viewing_tracker_ID
                          ? data.viewing_tracker_ID
                          : 'all'
                      }`}
                    >
                      <span className="is-size-7">View</span>
                    </a>
                    <a target="_blank" className="button last" href={applyHref}>
                      <span className="is-size-7">Apply</span>
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledProperty>
      <div
        className="section similar"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="container">
          <div className="columns" style={{ justifyContent: 'center' }}>
            <div className="column is-full">
              <hr />
              <p
                className="is-uppercase is-size-7"
                style={{ marginLeft: '8px', fontWeight: 500 }}
              >
                Comparable Listings
              </p>
            </div>
          </div>
        </div>
      </div>

      <Grid all_properties={gridProperties} />
    </Layout>
  );
};

const StyledProperty = styled('div')`
  .float-on-tablet {
    @media screen and (min-width: 768px) {
      float: right;
    }
  }
  .hide-on-mobile {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .show-on-mobile-only {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  padding-top: 80px;
  @media screen and (max-width: 960px) {
    padding-top: 40px;
  }
  h1 {
    margin-bottom: 0.5rem;
  }
  .meta {
    font-weight: 500;
  }
  .mainBlock {
    text-align: left;
    padding-bottom: 30px;
    font-size: 0.8em;
    @media screen and (max-width: 520px) {
      text-align: left;
    }
    margin: 1rem auto 2rem;
    p {
      margin-bottom: 1rem;
    }
    ul {
      padding: 10px;
      padding-left: 0;
    }
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
  .agent {
    p {
      margin-bottom: 0;
    }
    .phone {
      padding-top: 2px;
    }
    .image {
      width: 120px;
    }
  }

  .button {
    @media screen and (max-width: 520px) {
      width: 48%;
      &:first {
        margin-right: 1%;
      }
      &:last {
        margin-left: 1%;
      }
    }
    &.first {
      margin-right: 10px;
    }
  }
  .backLink {
    color: #000;
    text-transform: lowercase;
    font-weight: 500;
    padding-left: 3px;
    &:before {
      display: inline-block;
      content: ' ';
      background-size: 9px 9px;
      height: 9px;
      width: 12px;
      background-image: url(${arrowLeft});
      background-repeat: no-repeat;
      position: relative;
      bottom: 1px;
    }
  }
`;
