import { useStaticQuery, graphql } from 'gatsby';
import { format } from 'date-fns';
export default ({
  category = '',
  location = '',
  hideSlug = '',
  limit = null,
} = {}) => {
  // Need to filter these accordingly

  const data = useStaticQuery(graphql`
    query {
      allStrapiProperty {
        nodes {
          featured_image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          gallery {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          created_at
          available_from
          bedrooms
          bathrooms
          parks
          street_address
          address_2
          weekly_rent
          slug
          category
          location
          status
          hidden_from_website
          strapiId
        }
      }
    }
  `);

  const raw_properties = data.allStrapiProperty.nodes
    .filter(p => p.featured_image)
    .filter(p => !p.hidden_from_website);

  let properties = hideSlug
    ? raw_properties.filter(p => p.slug !== hideSlug)
    : raw_properties;

  properties.forEach(p => {
    p.dateOrderable = format(new Date(p.created_at), 'yyyyMMdd');
  });

  const propertiesWithCatFilterAndSorted = [
    ...properties
      .filter(p => 
        category && category != '' ? (p.category == category ? true : false) : true
      )
      .filter(p =>
        location && location != '' ? (p.location == location ? true : false) : true
      )
  ];

  const propertiesToReturn = [
    ...propertiesWithCatFilterAndSorted
      .filter(p => p.status == 'available')
      .sort((p1, p2) => {
        if (p1.dateOrderable > p2.dateOrderable) return -1;
        return 1;
      }),
    ...propertiesWithCatFilterAndSorted
      .filter(p => p.status != 'available')
      .sort((p1, p2) => {
        if (p1.dateOrderable < p2.dateOrderable) return 1;
        return -1;
      }),
  ].slice(0, limit ? limit : 1000);
  return propertiesToReturn;
};
