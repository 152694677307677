import React from 'react';
import { graphql } from 'gatsby';
import SingleProperty from '../components/singleProperty';

const Single = ({ data, location = false }) => (
  <SingleProperty data={data.singleProperty} location={location} />
);
export default Single;

/*
viewing_tracker_ID
tenancy_tracker_ID

*/
export const query = graphql`
  query($slug: String!) {
    singleProperty: strapiProperty(slug: { eq: $slug }) {
      featured_image {
        hero: childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 1070, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        lightbox: childImageSharp {
          hd: fixed(height: 900, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
          big: fixed(height: 800, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
          laptop: fixed(height: 550, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
          tablet: fixed(height: 400, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
          mobile: fixed(height: 275, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      gallery {
        localFile {
          hero: childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1070, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          lightbox: childImageSharp {
            hd: fixed(height: 900, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
            big: fixed(height: 800, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
            laptop: fixed(height: 550, quality: 70) {
              ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(height: 400, quality: 70) {
              ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(height: 275, quality: 70) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      bedrooms
      bathrooms
      parks
      street_address
      address_2
      title
      description
      weekly_rent
      slug

      member {
        id
        Email
        Name
        phone_number
        phone_number_linkable
        Description
        Position
        avatar {
          childImageSharp {
            fluid(maxWidth: 900, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      available_from
      status
      category
      location
    }
  }
`;

/*


*/
